import axios from 'axios'

// setup API interface
const API = axios.create({
  baseURL: 'https://back-end-flask.herokuapp.com/api',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// fetch a login token from the API
export const getAPIToken = (username, password, callback) => {
  // send POST request with username and password in body
  API.post('/login', JSON.stringify({ username, password }))
    .then(({ data }) => {
      if (data.success) {
        callback(null, data.authtoken)
      } else {
        callback("Incorrect username/password", null)
      }
    })
    .catch((error) => {
      console.error(error)
      callback("There was an error logging you in", null)
    })
}

// register a user with the backend
export const registerUser = (name, username, password, callback) => {
  // send POST request with name, username, and password in body
  API.post('/register', JSON.stringify({ name, username, password }))
    .then(({ data }) => {
      if (data.success) {
        callback(null)
      } else {
        callback("There was an error registering your account")
      }
    })
    .catch((error) => {
      console.error(error)
      callback("There was an error registering your account")
    })
}

// ask the server if a token is valid
export const verifyToken = (token, callback) => {
  // send GET request with the token in the Authorization header
  API.get('/verify_token', { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      callback(null, data.success)
    })
    .catch((error) => {
      console.error(error)
      callback(error)
    })
}

// input data to the backend
export const inputData = (token, start, end, type, date, callback) => {
  // send POST request with the data
  API.post('/input_data', JSON.stringify({ start, end, type, date }), { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      if (data.success) {
        callback(null)
      } else {
        callback("There was an error inputting that data.")      
      }
    })
    .catch((error) => {
      console.error(error)
      callback(error.message)
    })
}

// get data from the server for a given date
export const getCategoryData = (token, start, end, category, callback) => {
  // send POST request with date
  API.post('/daily_hours_category', JSON.stringify({ start, end, category }), { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      callback(null, data)
    })
    .catch((error) => {
      console.error(error)
      callback(error.message)
    })
}

// get streak from backend
export const getStreakData = (token, callback) => {
  // send GET request
  API.get('/get_current_streak', { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      callback(null, data)
    })
    .catch((error) => {
      console.error(error)
      callback(error.message)
    })
}

// get target data from backend
export const getTargetData = (token, callback) => {
  // send GET request
  API.get('/get_target', { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => {
      callback(null, data)
    })
    .catch((error) => {
      console.error(error)
      callback(error.message)
    })
}

// set target data with backend
export const setTargetData = (token, workTarget, sleepTarget, callback) => {
  // send POST request in correct format
  API.post('/set_target', JSON.stringify({ work_target: workTarget, sleep_target: sleepTarget }), { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data }) => {
    if (data.Success) {
      callback(null)
    } else {
      callback("There was an error inputting that data.")      
    }
  })
  .catch((error) => {
    console.error(error)
    callback(error.message)
  })
}