import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import M from 'materialize-css'
import moment from 'moment'

import { APIContext } from '../../apiWrapper'
import { Selector } from '../../components'

const ManualInput = () => {
  const history = useHistory()
  const context = useContext(APIContext)
  const [loading, setLoading] = useState(false)
  const [startTime, setStartTime] = useState(moment())
  const [endTime, setEndTime] = useState(moment())
  const [inputType, setInputType] = useState('null')
  const [error, setError] = useState("")

  useEffect(() => {
    // Init again on load to get the selection box to show
    M.AutoInit()
  }, [])

  const submitInput = (event) => {
    event.preventDefault()
    setLoading(true)
    setError("")

    if (inputType === 'null') {
      return setError('Please select an input type')
    }

    // convert start and end times into unix strings
    const startUnix = moment(startTime).unix()
    const endUnix = moment(endTime).unix()

    // check that end is after start time
    if (endUnix - startUnix <= 0) {
      return setError('Start time must be before end time')
    }

    context.submitData(startUnix, endUnix, inputType, (err) => {
      setLoading(false)

      if (err) return setError(err)

      // if successful, display success message and reset inputs
      M.toast({ html: 'Data inputted successfully' })
      setStartTime(moment())
      setEndTime(moment())
      setInputType('null')

      history.push('/dashboard')
    })
  }

  return (
    <div className='container row'>
      <div className='col s12 m6 offset-m3'>
        <div className='card'>
          <div className='card-content'>
            <span className="card-title">Manually Input Data</span>

            <form className='center-align' onSubmit={submitInput}>
              <div className='input-field'>
                <p>Start Time</p>
                <input
                  id='start-time'
                  type='datetime-local'
                  className='time-picker'
                  value={startTime}
                  onChange={e => setStartTime(e.target.value)}
                  required={true}
                />
              </div>
              <div className='input-field'>
                <p>End Time</p>
                <input
                  id='end-time'
                  type='datetime-local'
                  className='time-picker'
                  value={endTime}
                  onChange={e => setEndTime(e.target.value)}
                  required={true}
                />
              </div>
              <div className='input-field'>
                <p>Input Type</p>
                <Selector
                  options={["Sleep", "Productivity"]}
                  value={inputType}
                  onChange={e => setInputType(e.target.value)}
                  required={true}
                />
              </div>
              <button className='btn-small' submit='true'>Submit</button>
              <p className="input-error">{error}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManualInput