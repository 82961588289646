import React, { useState, useEffect } from 'react'
import ls from 'local-storage'
import jwtDecode from 'jwt-decode'
import M from 'materialize-css'

import { getAPIToken, verifyToken, registerUser, inputData, getCategoryData, getStreakData, getTargetData, setTargetData } from './lib/api'
import { getStoredToken } from './lib/login'

export const APIContext = React.createContext()

export const APIProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [token, setToken] = useState(getStoredToken())
  const [loading, setLoading] = useState(true)

  // initialise auth system
  // runs on initial load and when token updates
  useEffect(() => {
    // check if token is stored before doing anything
    if (token) {
      // check if token is expired
      const exp = jwtDecode(token).exp
      if (Date.now() >= exp * 1000) {
        M.toast({ html: 'Token expired, please log in again.' })
        logout()
      }

      // verify the token on the backend
      verifyToken(token, (err, valid) => {
        setLoading(false)

        if (err || !valid) {
          logout()
        } else {
          setUser(extractTokenData(token))
        }
      })
    } else {
      setLoading(false)
    }
  }, [token])

  /// AUTHENTICATION
  // register function
  const register = (name, username, password, callback) => {
    // register with backend
    registerUser(name, username, password, (err) => {
      if (err) return callback(err)

      callback(null)
    })
  }

  // login function
  const login = (username, password, callback) => {
    // fetch api token from backend
    getAPIToken(username, password, (err, token) => {
      if (err) return callback(err)

      // store token
      ls.set('token', token)
      setToken(token)
      setUser(extractTokenData(token))

      callback(null)
    })
  }

  // logout function
  const logout = () => {
    ls.clear()
    setUser(null)
    setToken(null)
  }

  // function to decode user data from a token
  const extractTokenData = (token) => {
    // decode token data
    const decoded = jwtDecode(token)
    // extract variable from decoded data
    const { is_admin, username } = decoded
    const userData = { is_admin, username }

    return userData
  }

  /// DATA EXCHANGE
  // function to input data to the database
  const submitData = (start, end, type, callback) => {
    // use today's date for the date field (use end date for date field as well as actual end field)
    inputData(token, start, end, type, end, (err) => {
      callback(err)
    })
  }

  // function to get data for a certain date from the database
  const getData = async (start, end, category, callback) => {
    getCategoryData(token, start, end, category, (err, data) => {
      callback(err, data)
    })
  }

  // function to get streak data
  const getStreak = async (callback) => {
    getStreakData(token, (err, data) => {
      callback(err, data)
    })
  }

  // function to get target data
  const getTargets = async (callback) => {
    getTargetData(token, (err, data) => {
      callback(err, data)
    })
  }

  // function to set target data
  const setTargets = (workTarget, sleepTarget, callback) => {
    setTargetData(token, workTarget, sleepTarget, (err) => {
      callback(err)
    })
  }

  // return the provider for the API
  return (
    <APIContext.Provider
      value={{
        user,
        loading,
        register,
        login,
        logout,
        submitData,
        getData,
        getStreak,
        getTargets,
        setTargets
      }}
    >
      {children}
    </APIContext.Provider>
  )
}
