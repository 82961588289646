import React, { useContext, useEffect, useState } from 'react'
import M from 'materialize-css'

import FireImage from '../../assets/fire.png'
import { APIContext } from '../../apiWrapper'

const Goals = () => {
  const context = useContext(APIContext)
  const [loading, setLoading] = useState(false)
  const [streak, setStreak] = useState(0)
  const [sleepGoal, setSleepGoal] = useState(0)
  const [prodGoal, setProdGoal] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    context.getStreak((err, data) => {
      if (err) return setError(err)

      setStreak(data.current_streak)
    })

    context.getTargets((err, data) => {
      if (err) return setError(err)

      setSleepGoal(data.sleep_target)
      setProdGoal(data.work_target)
    })
  }, [])

  const setGoals = (event) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    context.setTargets(prodGoal, sleepGoal, (err) => {
      setLoading(false)

      if (err) return setError(err)

      M.toast({ html: 'Targets updated successfully' })
    })
  }

  return (
    <div className='container row'>
      <div className='col s6 m3'>
        <div className='card'>
          <div className='card-content center'>
            <img
              draggable='false'
              src={FireImage}
              className={`mb25 ${streak > 0 ? '' : 'grey-fire'}`}
              width='100'
              height='100' 
            />
            <span className='card-title'><span className='big'>{streak}</span> DAY STREAK</span>
          </div>
        </div>
      </div>

      <div className='col s6 m9'>
        <div className='card'>
          <div className='card-content'>
            <span className='card-title'>My Goals</span>

            <form onSubmit={setGoals}>
              <p className='bold mt25'>Sleep</p>
              <input
                className='number-input'
                type='number'
                name='sleep-goal'
                value={sleepGoal}
                min={1}
                max={1440}
                onChange={e => setSleepGoal(e.target.value)}
              /> minutes of sleep per day.
              <p>{((sleepGoal / 60) * 7).toFixed(2)} hours of sleep per week.</p>

              <p className='bold mt25'>Productivity</p>
              <input
                className='number-input'
                type='number'
                name='prod-goal'
                value={prodGoal}
                min={1}
                max={1440}
                onChange={e => setProdGoal(e.target.value)}
              /> minutes of work per day.
              <p>{((prodGoal / 60) * 7).toFixed(2)} hours of work per week.</p>

              <button className='btn mt25' submit='true'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Goals