import jwtDecode from 'jwt-decode'
import ls from 'local-storage'

export const getStoredToken = () => {
  const token = ls.get('token')
  if (!token) return null

  return token
}

export const isLoggedIn = () => {
  const token = getStoredToken()
  if (!token) return false

  try {
    jwtDecode(token)
    return true
  } catch (err) {
    return false
  }
}