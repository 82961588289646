import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './index.scss'
import { APIContext } from '../../apiWrapper'
import { Panel, Button, TextBox } from '../../components'
import { isLoggedIn } from '../../lib/login'

const RegisterContainer = (props) => {
  // initialise context and state
  const context = useContext(APIContext)
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (isLoggedIn()) {
      props.history.push('/dashboard')
    }
  }, [props])

  // runs when form has been submitted
  const register = (event) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    // use APIContext login function
    context.register(name, username, password, (err) => {
      setLoading(false)

      // if there was an error, stop loading and display it
      if (err) {
        setError(err)
        return
      }

      // if all is well, display success message
      setSuccess(true)
    })
  }

  return (
    <div className='register-container container row'>
      <Helmet>
        <title>Register | Sleep Tracker</title>
      </Helmet>

      <div className='col m6 offset-m3'>
        <div className='card'>
          <div className='card-content'>
            <span className='card-title'>Register</span>

            {
              !success
                ? <form className='center-align' onSubmit={register}>
                    <div className='input-field'>
                      <input
                        type='text'
                        id='name'
                        className='validate'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required={true}
                      />
                      <label htmlFor='name'>Full Name</label>
                    </div>
                    <div className='input-field'>
                      <input
                        type='text'
                        id='username'
                        className='validate'
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required={true}
                      />
                      <label htmlFor='username'>Username</label>
                    </div>
                    <div className='input-field'>
                      <input
                        type='password'
                        id='password'
                        className='validate'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required={true}
                      />
                      <label htmlFor='password'>Password</label>
                    </div>
                    <button className='btn-small' submit='true'>Register</button>
                    <p className='input-error'>{error}</p>
                  </form>
                : <p className='center-align'>Registration successful! You may now <Link to="/login">login</Link>.</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterContainer