import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { Bar, Line } from 'react-chartjs-2'

import { APIContext } from '../../apiWrapper'

const Charts = () => {
  const context = useContext(APIContext)
  const [sleepData, setSleepData] = useState({})
  const [sleepError, setSleepError] = useState('')
  const [prodData, setProdData] = useState({})
  const [prodError, setProdError] = useState('')

  // fetch all the data on load
  useEffect(() => {
    const end = moment()
    end.set({ hour: 23, minute: 59, second: 59 })
    const start = moment().subtract(6, 'd')

    // sleep data
    context.getData(start.unix(), end.unix(), 'sleep', (err, data) => {
      if (err) {
        setSleepError(err)
        setSleepData({})
        return
      }

      const formattedData = {
        labels: Object.keys(data),
        datasets: [{
          label: 'hours of sleep',
          data: Object.values(data),
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        }]
      }

      setSleepData(formattedData)
    })

    // productivity data
    context.getData(start.unix(), end.unix(), 'productivity', (err, data) => {
      if (err) {
        setProdError(err)
        setProdData({})
        return
      }

      const formattedData = {
        labels: Object.keys(data),
        datasets: [{
          label: 'hours of productive work',
          data: Object.values(data),
          borderColor: 'rgb(75, 192, 192)'
        }]

      }

      setProdData(formattedData)
    })
  }, [])

  return (
    <div className='container row'>
      <div className='col s12 l6'>
        <div className='card'>
          <div className='card-content'>
            <span className='card-title'>Sleep</span>
            
            <Bar 
              data={sleepData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className='col s12 l6'>
        <div className='card'>
          <div className='card-content'>
            <span className='card-title'>Productivity</span>

            <Line 
              data={prodData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Charts