import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import M from 'materialize-css'

import './index.scss'
import 'materialize-css/dist/css/materialize.min.css'
import { LoginContainer, HomeContainer, DashboardContainer, RegisterContainer } from './containers'
import { NavBar } from './components'
import { APIContext } from './apiWrapper'

const App = () => {
  const context = useContext(APIContext)

  M.AutoInit()

  return (
    <Router>
      <div className='main-container'>
        <NavBar username={context.user ? context.user.username : null} logoutFunction={context.logout} />
        <Switch>
          <Route exact path='/' component={HomeContainer} />
          <Route exact path='/login' component={LoginContainer} />
          <Route exact path='/register' component={RegisterContainer} />
          <Route path='/dashboard' component={DashboardContainer} />
        </Switch>
      </div>
    </Router>
  )
}

export default App;