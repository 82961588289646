import React, { useEffect } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import './index.scss'
import ManualInput from './manual-input'
import Charts from './charts'
import Goals from './goals'
import { APIContext } from '../../apiWrapper'
import { isLoggedIn } from '../../lib/login'

const DashboardContainer = (props) => {
  useEffect(() => {
    if (!isLoggedIn()) {
      props.history.push('/login')
    }
  }, [props])

  return (
    <div className='dashboard-container'>
      <Helmet>
        <title>Dashboard | Sleep Tracker</title>
      </Helmet>

      <nav className='grey darken-4'>
        <div className='nav-wrapper container'>
          <ul>
            <li><Link to='/dashboard'>Dashboard</Link></li>
            <li><Link to='/dashboard/manual-input'>Input Data</Link></li>
            <li><Link to='/dashboard/goals'>Streak and Goals</Link></li>
          </ul>
        </div>
      </nav>

      <TransitionGroup>
        <CSSTransition
          key={props.location.key}
          classNames='slide'
          timeout={300}
          unmountOnExit={true}
        >
          <Switch location={props.location}>
            <Route exact path='/dashboard' component={Charts} />
            <Route exact path='/dashboard/manual-input' component={ManualInput} />
            <Route exact path='/dashboard/goals' component={Goals} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default DashboardContainer