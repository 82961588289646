import React from 'react'

import './index.scss'

const Selector = ({ options, ...props }) => {
  return (
    <select {...props}>
      <option value='null' disabled={true}>Choose an option</option>
      {
        options.map(opt => <option key={opt} value={opt.toLowerCase()}>{opt}</option>)
      }
    </select>
  )
}

export default Selector