import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import './index.scss'
import { isLoggedIn } from '../../lib/login'

const HomeContainer = (props) => {
  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn()) {
      props.history.push('/dashboard')
    }
  }, [props])

  return (
    <div className='home-container'>
      
    </div>
  )
}

export default HomeContainer