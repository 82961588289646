import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './index.scss'
import { APIContext } from '../../apiWrapper'
import { Panel, Button, TextBox } from '../../components'
import { isLoggedIn } from '../../lib/login'

const LoginContainer = (props) => {
  // initialise context and state
  const context = useContext(APIContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (isLoggedIn()) {
      props.history.push('/dashboard')
    }
  }, [props])

  // runs when form has been submitted
  const login = (event) => {
    event.preventDefault()
    setLoading(true)
    setError("")

    // use APIContext login function
    context.login(username, password, (err) => {
      // if there was an error, stop loading and display it
      if (err) {
        setLoading(false)
        setError(err)
        return
      }

      // if all is well, go to the dashboard
      props.history.push('/dashboard')
    })
  }

  return (
    <div className='login-container container row'>
      <Helmet>
        <title>Login | Sleep Tracker</title>
      </Helmet>

      <div className='col m6 offset-m3'>
        <div className='card'>
          <div className='card-content'>
            <span className='card-title'>Login</span>

            <form className='center-align' onSubmit={login}>
              <div className='input-field'>
                <input
                  type='text'
                  id='username'
                  className='validate'
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  required={true}
                />
                <label htmlFor='username'>Username</label>
              </div>
              <div className='input-field'>
                <input
                  type='password'
                  id='password'
                  className='validate'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required={true}
                />
                <label htmlFor='password'>Password</label>
              </div>
              <button className='btn-small' submit='true'>Login</button>
              <p className="input-error">{error}</p>
            </form>

            <p className="no-account center-align">Don't have an account? <Link to='/register'>Register</Link></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer