import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = ({ username, logoutFunction }) => {
  return (
    <nav className='top-nav grey darken-4'>
      <div className='nav-wrapper container'>
        <p className='brand-logo left'>Sleep Tracker</p>
        <ul id='nav-mobile' className='right'>
          {
            username
              ? <>
                  <li>Logged in as <span className='username'>{username}</span></li>
                  <li><a className='btn-small' onClick={logoutFunction}>Logout</a></li>
                </>
              : <>
                  <li><Link className='btn-small' to='/login'>Login</Link></li>
                  <li><Link className='btn-small' to='/register'>Register</Link></li>
                </>
          }
        </ul>
      </div>
    </nav>
  )
}

export default NavBar